<template>
  <v-container>
      <v-row>

        <v-col cols="12">
          <v-card class="mx-auto" max-width="344">
            <v-img
              src="../assets/image_default.jpg"
              height="200px"
            ></v-img>
            <v-card-subtitle class="mb-0 pb-0">      
              LPP Mars
            </v-card-subtitle>
            <v-card-subtitle class="mt-0 pt-0 mb-0 pb-0">      
              St. Valikhanov 63
            </v-card-subtitle>
            <v-card-title class="mt-0 pt-0">
              Free parking lot: {{info.data.mars1Count}}
            </v-card-title>
          </v-card>
        </v-col>

        <v-col cols="12">
          <v-card class="mx-auto" max-width="344">
          <v-img
            src="../assets/mars2.jpg"
            height="200px"
          ></v-img>
          <v-card-subtitle class="mb-0 pb-0">
            LPP Mars
          </v-card-subtitle>
          <v-card-subtitle class="mt-0 pt-0 mb-0 pb-0">
            St. Kunaev 77
          </v-card-subtitle>
          <v-card-title class="mt-0 pt-0">      
            Free parking lot: {{info.data.mars2Count}}
          </v-card-title>
        </v-card>
      </v-col>

    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios';

export default {
  name: 'Mars',
  data() {
    return {
      info: null
    };
  },
  mounted() {
    axios
      .get('https://acs.megapass.kz/api/landing')
      .then(response => (this.info = response));
  }
}
</script>
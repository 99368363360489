<template>
  <v-app>
    <v-main>
      <Mars/>
    </v-main>
  </v-app>
</template>

<script>
import Mars from './components/Mars';

export default {
  name: 'App',

  components: {
    Mars,
  },

  data: () => ({
    //
  }),
};
</script>
